<template>
  <base-layout title="Queue">
    <ion-card style="background-color: #FAE8E0; border-radius: 30px" class="ion-text-center">
      <br>
      <ion-card-header style="font-weight: bold; margin-left: auto; margin-right: auto; border: solid 1px #F9F1F0; border-radius: 10px; width: 60%; font-size: 20px; background-color: #F9F1F0">In Progress</ion-card-header>
      <ion-card-list>
        <ion-card-content v-for="order in inProgress" :key="order.id" class="ion-text-center" style="color: gray; font-size: 19px">
          {{order.userName}}
        </ion-card-content>
      </ion-card-list>
    </ion-card>

    <ion-card style="background-color: #9EDEC6; border-radius: 30px" class="ion-text-center">
        <br>
        <ion-card-header style="font-weight: bold; margin-left: auto; margin-right: auto; border: solid 1px #d1ffe6; border-radius: 10px; width: 60%; font-size: 20px; background-color: #d1ffe6"> Ready for Pickup </ion-card-header>
        <ion-card-list >
        <ion-card-content v-for="order in complete" :key="order.id" style="color: white; font-size: 19px">
          {{order.userName}}
        </ion-card-content>
      </ion-card-list>
      </ion-card>
      <ion-content class="ion-text-center">
        Orders are not always prepared in order of placement.
      </ion-content>

  </base-layout>
</template>

<script>


export default  {
  
  data() {
    return {
      

    }
  },
  computed: {
    orders() {
      return this.$store.getters.orders
    },
    inProgress() {
      let progressList = []
      if (this.orders) {
        for (let i = 0; i < this.orders.length; i++) {
          if (this.orders[i].status == "In Progress") {
            progressList.push(this.orders[i])
          }
        }
      }
      

      return progressList
    },
    complete() {
      let completeList = []
      if (this.orders) {
        for (let i = 0; i < this.orders.length; i++) {
          if (this.orders[i].status == "Finished") {
            completeList.push(this.orders[i])
          }
        }
      }
      

      return completeList
    },
  },
  mounted() {
    if (this.orders == null) {
      this.$store.dispatch('loadOrders');
      this.$store.dispatch('loadNotCancelled');
    }
  }
}
</script>